import React from "react";
import MowerSearchResult from "./MowerSearchResult";


import { makeStyles, Theme, createStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Card, CardActionArea, CardMedia, CardContent } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { withStyles} from "@material-ui/core/styles";
import { Grid, InputAdornment, FormHelperText, } from "@material-ui/core";
import { blue, cyan, lightBlue} from "@material-ui/core/colors";
import { whileStatement } from "@babel/types";
import { height } from "@material-ui/system";


 // create theme with button labels not Uppercase
 const wizardButtonTheme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: cyan,
  },
  overrides: {
      MuiButton:{
        
        label: {
            textTransform: 'none',
            fontSize: 'medium',
        },        
      },

      MuiTooltip: {
        tooltip : {
          marginTop: '30px',
        }
      },
      // NEVE TEMPLATE CONFLICT FIXES
      MuiStepButton: {
        root: {
          "&:hover":  {
            backgroundColor: "#fff",
            color: "#000",
          }          
        }
      },

      MuiCardActionArea: {
        root: {
          "&:hover":  {
            backgroundColor: "#fff",
            color: "#000",
          }          
        }
      },
    
      MuiInput:{
        root: {
          "& input": {
            border: '0px',
          }
        }
      },
      MuiTable: {
        root: {
          margin: '0px',
        }
      },
      MuiTableCell: {
        root: {
          textTransform: 'none',
        }
      },
      MuiSvgIcon: {
        fontSizeLarge: {
          width: '1.5em',
          height: '1.5em',
        }
      },
      MuiTypography: {
        root: {
          textTransform: 'none',
        }
      },
      // END -- NEVE TEMPLATE CONFLICT FIXES
  },
  spacing: '30',
});

const useStyles = theme =>(
  {
    root: {
        width: '100%',
        height:'100vh', 
        overflowY:'scroll', 
        scrollSnapType:'y mandatory'
    },
    media: {
      height:'300px',
      backgroundSize: 'contain',
    },
    subtitle1:{
      fontSize: '0.7rem',
    },
    button: {
        color: 'white',            
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1)       
    },   
    optionalStep: {
        
    },
    wizardContent: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1),
    },
    textinput:{
      marginRight: theme.spacing(1),
    },
    rotate90: {
        transform: "rotate(-45deg)"
    },
   
    wizardImageContent:{
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    }

  }
);



class  Wizard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      searchResultList: [],
      mowerDB: [],      
      selection: {gardensize:0, maxsize:5000, passagesize: 0, inclination:0, displayAvailable: undefined, appAvailable: undefined},
      completedSteps: {},
      mandatoryCompleted: false,
      activeStep: 0,
      passageSizeInput: '',
      gardenSizeInput: ''
    };

    const loadDB = () => {               
        fetch('https://xn--mhroboter-installation-04b.de/wp-json/wp/v2/mower-models?per_page=100')
        .then(res => res.json())        
        .then((data) => {                 
          this.setState({mowerDB: [...data]});
        })
        .catch(console.log)
      }
    

    loadDB();


  }


  getSteps() {
    return ['Select campaign settings', 'Create an ad group', 'Create an ad'];
  }
  
  getStepContent(step) {
    switch (step) {
      case 0:
        return {text: `For each ad campaign that you create, you can control how much
                you're willing to spend on clicks and conversions, which networks
                and geographical locations you want your ads to show on, and more.`,
                next: 'abs'
                };
      case 1:
        return {text: 'An ad group contains one or more ads which target a shared set of keywords.',
        next: 'abs'
        };
      case 2:
        return {text: `Try out different ad text to see what brings in the most customers,
                and learn how to enhance your ads using features like ad extensions.
                If you run into any problems with your ads, find out how to tell if
                they're running and how to resolve approval issues.`,
                next: 'abs'
                };
      default:
        return 'Unknown step';
    }}

  handleNext (newSelection, index) {

    this.setState({activeStep: (this.state.activeStep + 1)});
    this.setState({selection: {...this.state.selection, ...newSelection}});

    this.handleComplete();
    
  };
  
    handleComplete(){
      const newCompleted = this.state.completedSteps;
      newCompleted[this.state.activeStep] = true;
      this.setState(
        {
          completed:newCompleted,
          mandatoryCompleted: newCompleted[0] && newCompleted[1] && newCompleted[2] && newCompleted[3] 
        });            

    };

    handleBack() {
      this.setState({activeStep: (this.state.activeStep - 1)});
    };
  
    handleReset() {
      this.setState({selection: {gardensize:0, maxsize:5000, passagesize: 0, inclination:0, displayAvailable: undefined, appAvailable: undefined}});
      this.setState({searchResultList:[]});
      this.setState({activeStep: 0});
      
    };

    searchForResult() {

        let filtered = this.state.mowerDB.filter((entry) => parseInt(entry.acf.gardensize) >=  this.state.selection.gardensize);
        filtered = filtered.filter((entry) => parseInt(entry.acf.gardensize) <=  this.state.selection.maxsize);
        filtered = filtered.filter((entry) => parseInt(this.state.selection.passagesize) >= parseInt(entry.acf.passagesize));
        filtered = filtered.filter((entry) => this.state.selection.displayAvailable === undefined || this.state.selection.displayAvailable === entry.acf.display_menu);
        filtered = filtered.filter((entry) => this.state.selection.appAvailable === undefined || this.state.selection.appAvailable === entry.acf.menufuhrung_per_app);
        filtered = filtered.filter((entry) => parseInt(entry.acf.inclination) >= this.state.selection.inclination);        
        this.setState({searchResultList:[...filtered]});
    }

    handlePassageSizeInputChange = (event) => {      
      this.setState({passageSizeInput: event.target.value});
    }

    handleGardenSizeInputChange = (event) => {      
      this.setState({gardenSizeInput: event.target.value});
    }

    handleStep = (step) => () => {      
      this.setState({activeStep: step});
    };
  
    componentDidUpdate(prevProps, prevState) {      
      if( prevState.selection){
        if (this.state.selection.gardensize !== prevState.selection.gardensize 
          || this.state.selection.maxsize !== prevState.selection.maxsize
          || this.state.selection.passagesize !== prevState.selection.passagesize
          || this.state.selection.displayAvailable !== prevState.selection.displayAvailable 
          || this.state.selection.appAvailable !== prevState.selection.appAvailable 
          || this.state.selection.inclination !== prevState.selection.inclination  ) {
          this.searchForResult();
        }
      }
    }

  calculatGardenMaxSize(){

    var sizeInput = parseInt(this.state.gardenSizeInput);
    if(sizeInput < 200){
      return 400; 
    } else {
      if(sizeInput < 1000){
        return sizeInput + 300;
      } else {
        return sizeInput * 2;
      }
    }
  }

   

  render(){

    const  { classes } = this.props;
    const optionsButtonVariant = "contained";
    const optionsButtonSizeoptionsButtonSize = "small";
    const optionsButtonColor = "primary";

    return (

    <ThemeProvider theme={wizardButtonTheme}>
      
    
      <div className={classes.root}  >
        <Grid  container
            direction="row"
            justifyContent="space-evenly"
            alignItems="start"

            >
          <Grid item xs={12} sm={8} style={{scrollSnapType: 'y',  scrollSnapStop: 'normal', scrollSnapAlign: 'start',}}>
            <Stepper activeStep={this.state.activeStep} orientation="vertical" >
            
            <Step key='gardensize'>
              
              <StepButton onClick={this.handleStep(0)} completed={this.state.completedSteps[0]} >
                <Typography>Wie groß ist Ihr Garten?</Typography>
              </StepButton>
              <StepContent>                
                <Paper className={classes.wizardContent} elevation={3}>

                  <TextField
                        id="gardensizeinput"
                        label="Gartengröße in m²"
                        type="number"                       
                        value={this.state.gardenSizeInput}        
                        onChange={this.handleGardenSizeInputChange}                        
                        size="small"       
                        endAdornment={<InputAdornment position="end">m²</InputAdornment>}
                        className={classes.textinput}   
                        aria-describedby="gardensizeinput-error"           
                      />
                      
                      <Button disabled={!this.state.gardenSizeInput || !(parseInt(this.state.gardenSizeInput) > 0)} onClick={() => this.handleNext({gardensize:parseInt(this.state.gardenSizeInput),maxsize: this.calculatGardenMaxSize(this.state.gardenSizeInput)})} color={optionsButtonColor} variant={optionsButtonVariant} size={optionsButtonSizeoptionsButtonSize} component="span" className={classes.button}>            
                          Weiter
                      </Button>                
                </Paper>
              </StepContent>
            </Step>


            <Step key='passages'>
              <StepButton onClick={this.handleStep(1)} completed={this.state.completedSteps[1]} >
                <Typography>Passagenbreite</Typography>
              </StepButton>          
              <StepContent>

              <Paper className={classes.wizardContent} elevation={3}>
                <Typography>Haben Sie eine Passage, die der Mäher durchfahren muss? Wenn ja, wie breit ist die engste Stelle? (von Beet zu Beet, Zaun zu Zaun, Wand zu Wand, etc..) </Typography>
                <br/>
              
                    <div>
                      <TextField
                        id="passagesizeinput"
                        label="Passagengröße in cm"
                        type="number"                       
                        value={this.state.passageSizeInput}        
                        onChange={this.handlePassageSizeInputChange}  
                        error={this.state.passageSizeInput && parseInt(this.state.passageSizeInput) < 60}
                        size="small"       
                        endAdornment={<InputAdornment position="end">cm</InputAdornment>}
                        className={classes.textinput}   
                        aria-describedby="passagesizeinput-error"           
                      />                      
                      {this.state.passageSizeInput && parseInt(this.state.passageSizeInput) < 60 && <Typography color="error">Passagen enger als 60cm können von keinem Mähroboter durchfahren werden. Es müssen getrennte Flächen installiert und der Mäher muss von Hand umgesetzt werden. Schreiben Sie uns, wenn Sie zwei oder mehrere getrennte Flächen haben.</Typography>}
                      <Button disabled={!this.state.passageSizeInput || parseInt(this.state.passageSizeInput)< 60} onClick={() => this.handleNext({passagesize:this.state.passageSizeInput})} color={optionsButtonColor} variant={optionsButtonVariant} size={optionsButtonSizeoptionsButtonSize} component="span" className={classes.button}>            
                          Weiter
                      </Button>
                    </div>
                    <Button onClick={() =>{ this.setState({passageSizeInput:""}); this.handleNext({passagesize:1000}); } } color={optionsButtonColor} variant={optionsButtonVariant} size={optionsButtonSizeoptionsButtonSize} component="span" className={classes.button}>            
                        Nein
                    </Button>      
                  </Paper>
              </StepContent>
            </Step>

            <Step key='inclination'>              
              <StepButton onClick={this.handleStep(2)} completed={this.state.completedSteps[2]}>
                <Typography>Steigung</Typography>
              </StepButton>
              <StepContent>
                <Paper className={classes.wizardContent} elevation={3}>
                  <Typography>Haben Sie Steigungen im Garten und wenn ja, wie stark?</Typography>
                  <br/>
                  <Grid  container direction="row" justify="flex-start" alignItems="center">
                    <Grid item  container direction="column" justify="center" alignItems="center" style={{maxWidth: '100px'}}>
                      <Button onClick={() => this.handleNext({inclination:0})} color={optionsButtonColor} variant={optionsButtonVariant} size={optionsButtonSizeoptionsButtonSize} className={classes.button}>        
                          Nein  
                      </Button>     
                      <SquareFootIcon style={{transform: "rotate(-45deg)", margin:'15px',}} fontSize="large" />   
                    </Grid>
                    <Grid item  container direction="column" justify="center" alignItems="center"  style={{maxWidth: '100px'}}>
                      <Button onClick={() => this.handleNext({inclination:25})} color={optionsButtonColor} variant={optionsButtonVariant} size={optionsButtonSizeoptionsButtonSize} className={classes.button} >        
                          ja,&nbsp;mittel
                      </Button>
                      <SquareFootIcon style={{transform: "rotate(-65deg)", margin:'15px',}} fontSize="large" />
                    </Grid>
                    <Grid item container direction="column" justify="center" alignItems="center"  style={{maxWidth: '100px'}}>
                      <Button onClick={() => this.handleNext({inclination:35})} color={optionsButtonColor} variant={optionsButtonVariant} size={optionsButtonSizeoptionsButtonSize} className={classes.button}>        
                          ja,&nbsp;stark
                      </Button> 
                      <SquareFootIcon style={{transform: "rotate(-90deg) ", margin:'15px',}} fontSize="large" />

                    </Grid>
                    </Grid>
                    <Typography className={classes.subtitle1} ><HelpOutlineIcon/> Bitte beachten Sie dass das Gefälle am unteren Begrenzungsbereich nicht über 17% betragen sollte.</Typography>
                  </Paper>
              </StepContent>
            </Step>

            <Step key='app'>
              <StepButton onClick={this.handleStep(3)} completed={this.state.completedSteps[3]}>
                <Typography>Mähroboter mit Smartphone oder Tablet steuern</Typography>
              </StepButton>              
              <StepContent>               
                <Paper className={classes.wizardContent} elevation={3}>
                    <Button onClick={() => this.handleNext({appAvailable: true})} component="span" color={optionsButtonColor} variant={optionsButtonVariant} size={optionsButtonSizeoptionsButtonSize} className={classes.button}>            
                      Ja
                    </Button>
                    <Button onClick={() => this.handleNext({appAvailable: false})} component="span" color={optionsButtonColor} variant={optionsButtonVariant} size={optionsButtonSizeoptionsButtonSize} className={classes.button}>            
                      nein
                    </Button>                   
                  </Paper>
              </StepContent>
            </Step>
          
          
          </Stepper>
        </Grid>
        </Grid>
        
        {this.state.mandatoryCompleted && (               
          <MowerSearchResult searchResultList={this.state.searchResultList} gardenSizeInput={this.state.selection.gardensize}/>                           
        )}
      </div>
        
      </ThemeProvider>
    );
  }
};

export default withStyles(useStyles)(Wizard);

