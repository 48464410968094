import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import MowerTableRow from './MowerTableRow'
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { Badge, Tooltip, Typography, Button } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


const useStyles = makeStyles((theme) => ({
  paper: {
    height: '80vh',
    width: '100%',
    scrollSnapType: 'y',
    marginBottom: theme.spacing(2),
    scrollSnapStop: 'always',
    scrollSnapAlign: 'start',
    padding: '5px',
  },
  tableContainer: {
    maxHeight: '100vh',
  },
  table: {
  },
  tableBody: {
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  tooltipText: {
    fontSize: '0.7rem',
    width: '200px',
  },
  tableCell: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: 1,
      paddingLeft: 1,
      fontSize: '0.6rem',
    },
  },

  fixedButtonColor: {
    [theme.breakpoints.down('sm')]: {
      "& .MuiButton-label": {
        paddingRight: 1,
        paddingLeft: 1,
        fontSize: '0.6rem!important',
      },
    },

    "&:hover": {
      color: "#000",
    },


  },

}));



var undergroundPrices = {
  "100": 290,
  "200": 290,
  "210": 323,
  "220": 327,
  "230": 331,
  "240": 335,
  "250": 339,
  "260": 344,
  "270": 348,
  "280": 352,
  "290": 356,
  "300": 360,
  "310": 365,
  "320": 369,
  "330": 373,
  "340": 377,
  "350": 381,
  "360": 386,
  "370": 390,
  "380": 394,
  "390": 396,
  "400": 397,
  "410": 401,
  "420": 405,
  "430": 409,
  "440": 413,
  "450": 417,
  "460": 421,
  "470": 425,
  "480": 429,
  "490": 433,
  "500": 435,
  "510": 439,
  "520": 443,
  "530": 447,
  "540": 451,
  "550": 455,
  "560": 459,
  "570": 463,
  "580": 467,
  "590": 470,
  "600": 474,
  "610": 478,
  "620": 482,
  "630": 486,
  "640": 490,
  "650": 494,
  "660": 498,
  "670": 497,
  "680": 498,
  "690": 498,
  "700": 501,
  "710": 505,
  "720": 509,
  "730": 509,
  "740": 511,
  "750": 512,
  "760": 513,
  "770": 514,
  "780": 515,
  "790": 516,
  "800": 517,
  "810": 518,
  "820": 519,
  "830": 521,
  "840": 522,
  "850": 523,
  "860": 524,
  "870": 525,
  "880": 526,
  "890": 527,
  "900": 528,
  "910": 529,
  "920": 530,
  "930": 531,
  "940": 532,
  "950": 533,
  "960": 534,
  "970": 535,
  "980": 536,
  "990": 537,
  "1000": 538,
  "1500": 569,
  "2000": 619,
  "2500": 639,
  "3000": 659
};
var overgroundPrices = {
  "100": 199,
  "200": 229,
  "210": 239,
  "220": 244,
  "230": 248,
  "240": 253,
  "250": 257,
  "260": 262,
  "270": 266,
  "280": 271,
  "290": 275,
  "300": 280,
  "310": 284,
  "320": 289,
  "330": 293,
  "340": 298,
  "350": 302,
  "360": 307,
  "370": 311,
  "380": 316,
  "390": 320,
  "400": 325,
  "410": 329,
  "420": 334,
  "430": 338,
  "440": 343,
  "450": 347,
  "460": 352,
  "470": 356,
  "480": 361,
  "490": 365,
  "500": 370,
  "510": 374,
  "520": 379,
  "530": 383,
  "540": 388,
  "550": 392,
  "560": 397,
  "570": 401,
  "580": 406,
  "590": 410,
  "600": 415,
  "610": 415,
  "620": 416,
  "630": 420,
  "640": 424,
  "650": 428,
  "660": 433,
  "670": 437,
  "680": 437,
  "690": 441,
  "700": 442,
  "710": 444,
  "720": 444,
  "730": 447,
  "740": 448,
  "750": 449,
  "760": 451,
  "770": 455,
  "780": 460,
  "790": 464,
  "800": 465,
  "810": 466,
  "820": 467,
  "830": 468,
  "840": 469,
  "850": 470,
  "860": 471,
  "870": 472,
  "880": 473,
  "890": 474,
  "900": 475,
  "910": 476,
  "920": 477,
  "930": 478,
  "940": 479,
  "950": 480,
  "960": 481,
  "970": 482,
  "980": 483,
  "990": 484,
  "1000": 485,
  "1500": 519,
  "2000": 579,
  "2500": 599,
  "3000": 649
};



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function installationPrice(gardenSizeInput) {

  var price = undergroundPrices[gardenSizeInput];

  while (gardenSizeInput < 3000 && price === undefined) {
    if (gardenSizeInput % 10 > 0) {
      gardenSizeInput++;
    } else {
      gardenSizeInput += 10;
    }
    price = undergroundPrices[gardenSizeInput];
  }

  if (!price) {
    return "k.A.";
  }
  return price + '€';
}


function createRow(mower, gardenSizeInput) {

  return {
    title: mower.title.rendered,
    lautstarke: mower.acf.lautstarke,
    flachenleistung_pro_akkulaufzeit: mower.acf.flachenleistung_pro_akkulaufzeit,
    passagesize: parseInt(mower.acf.passagesize),
    regensensor: mower.acf.regensensor,
    display_menu: mower.acf.display_menu,
    menufuhrung_per_app: mower.acf.menufuhrung_per_app,
    url: mower.url,
    price: parseInt(mower.acf.preis_value),
    mower
  }
}


const headCells = [
  { id: 'title', numeric: false, disablePadding: true, label: 'Modell' },
  { id: 'rand', numeric: true, disablePadding: false, disabledSort: true, label: 'Randbreite', tooltip: 'Wie viel Gras bleibt am Rand stehen wenn Hindernisse nach dem Begrenzungsgrad folgen (Wand, Beet, Zaun). Mit entsprechenden Randsteinen kann dem entgegengewirkt werden.' },
  { id: 'lautstarke', numeric: true, disablePadding: false, label: 'Lautstärke' },
  { id: 'flachenleistung_pro_akkulaufzeit', numeric: true, disablePadding: false, label: 'Fläche pro Akkuladung' },
  { id: 'regensensor', checkbox: true, disablePadding: true, label: 'Regen-sensor' },
  { id: 'display_menu', checkbox: true, disablePadding: true, label: 'Erweiterte Funktionen am Display', tooltip: 'Display menü (nein) = Erweiterte Funktionen nur per App vollständig nutzbar, falls App vorhanden' },
  { id: 'passagesize', numeric: true, disablePadding: true, label: 'Min. Passagen breite' },
  { id: 'price', numeric: true, disablePadding: true, label: 'Angebot' },
  { id: 'installationPrice', numeric: false, disabledSort: true, disablePadding: true, label: 'Installations Preis', tooltip: 'Preis einer unterirdischen Mähroboter Installation inkl. Anfahrt und MwSt.' },
];


function EnhancedTableLabel(props) {
  const { label, tooltip } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  if (tooltip) {
    return (

      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableTouchListener
            disableHoverListener
            title={<Typography className={classes.tooltipText}>{tooltip}</Typography>}
          >
            <Button className={`${classes.fixedButtonColor} ${classes.tableCell}`} onClick={handleTooltipOpen} onMouseOver={handleTooltipOpen} onMouseOut={handleTooltipClose} endIcon={<HelpOutlineIcon />}>{label} </Button>
          </Tooltip>
        </div>
      </ClickAwayListener>
    );

  }
  return (
    <span>{label}</span>
  );
}


function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          { // empty placehoder  
          }
        </TableCell>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={(headCell.numeric || headCell.checkbox) ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCell}
          >
            {!headCell.disabledSort &&
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                <EnhancedTableLabel tooltip={headCell.tooltip} label={headCell.label} />

                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>}
            {headCell.disabledSort === true &&
              <EnhancedTableLabel tooltip={headCell.tooltip} label={headCell.label} />
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};





const MowerTable = ({ mowers, gardenSizeInput }) => {

  const rows = mowers.map(mower => createRow(mower, gardenSizeInput));
  const dense = false;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Paper className={classes.paper} elevation={0}>
      <TableContainer className={classes.tableContainer}>
        <Table
          stickyHeader={true}
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody className={classes.tableBody}>
            {stableSort(rows, getComparator(order, orderBy))
              .map((row, index) => {
                return (
                  <MowerTableRow row={row.mower} index={index} installationPrice={installationPrice(gardenSizeInput)} gardenSizeInput={gardenSizeInput} />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default MowerTable;
