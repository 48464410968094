import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Link from '@material-ui/core/Link';
import TableCell from '@material-ui/core/TableCell';
import Grid from '@material-ui/core/Grid';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ClearSharp from '@material-ui/icons/ClearSharp';
import OpenInNewSharpIcon from '@material-ui/icons/OpenInNewSharp';
import DoneSharp from '@material-ui/icons/DoneSharp';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { CardHeader } from '@material-ui/core';


const useStyles =  makeStyles((theme) => ({
  root: {
  
  },
  card: {
    maxWidth: 345,
    position: 'sticky', 
    left: '0',    
    margin: '3px 0',
  },
  cardContent: {
    [theme.breakpoints.down('sm')] : {
      display: "flex",
    },
  },
  text: {
    [theme.breakpoints.down('sm')] : {
      fontSize: '0.6rem',
    },
  },
  media: {
    // height: 140, // 

    [theme.breakpoints.down('sm')] :{
      width: '33%',
    },
  },
  grid: {
    [theme.breakpoints.up('sm')] :{
      margin: 10,
    },
  },
  tableCell: {
    [theme.breakpoints.down('sm')] : {
      paddingRight: 0,
      paddingLeft: 0,
      fontSize: '0.6rem',
    },
  },
  detailTableCell: {
    [theme.breakpoints.down('sm')] : {
      fontSize: '0.6rem',
    },
  },
}));


const CheckMarkIcon = (props) => {

  if(props.value === true) {
    return (<DoneSharp color="action"/>);
  } else{  
    return (<ClearSharp color="error" />);    
  }
}

const MowerTableRow = ({row, index, installationPrice, gardenSizeInput}) => {


  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const labelId = `enhanced-table-checkbox-${index}`;
  const installationLinkUrl = 'https://mähroboter-installation.de?installationType=undergroundInstallation&area=' + gardenSizeInput;


  return (
    <React.Fragment>
      <TableRow 
        className={classes.root}
        hover
        // onClick={(event) => handleClick(event, row.name)}
        role="checkbox"  
        tabIndex={-1}
        key={row.title.rendered}    
        onClick={() => setOpen(!open)}
      >
        
        <TableCell className={classes.tableCell} component="th">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>                  
        <TableCell className={classes.tableCell} id={labelId} padding='none'>
          {row.title.rendered}
        </TableCell>

        <TableCell className={classes.tableCell} align="center" >{row.acf.rand_in_cm} cm</TableCell>          
        <TableCell className={classes.tableCell} align="center" >{row.acf.lautstarke} db</TableCell>      
        <TableCell className={classes.tableCell} align="center" >{row.acf.flachenleistung_pro_akkulaufzeit} m²</TableCell>                             
        <TableCell className={classes.tableCell} align="center" padding='none'><CheckMarkIcon value={row.acf.regensensor} /></TableCell>
        <TableCell className={classes.tableCell} align="center" padding='none'><CheckMarkIcon value={row.acf.display_menu} /></TableCell>
        <TableCell className={classes.tableCell} align="center" padding='none'>{row.acf.passagesize} cm</TableCell>
        <TableCell className={classes.tableCell} align="center" padding='none'>
          <span style={{verticalAlign:"super"}}><b>ab {row.acf.preis_value} €</b></span>&nbsp;&nbsp;
          <Link href={row.acf.preisvergleich_url} target="_blank">
             <OpenInNewSharpIcon />
          </Link>
        </TableCell>      
        <TableCell className={classes.tableCell} align="center" padding='none'>
          <span style={{verticalAlign:"super"}}><b>{installationPrice}</b></span>
          <Link href={installationLinkUrl} target="_blank">
             <OpenInNewSharpIcon />
          </Link>
        </TableCell>    
        
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="center"
            className={classes.grid}
          >
            <Grid item xs={12} sm={5}>
              <Card className={classes.card} elevation={2} >
                <CardHeader title={row.title.rendered} titleTypographyProps={{variant:"body2"}} />
                <CardActionArea  className={classes.cardContent}>                 
                  <CardMedia
                    component="img"
                    className={classes.media}
                    image={row.acf.image.sizes.medium}
                    title={row.title.rendered}
                  />
                  <CardContent  className={classes.cardContent}>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.text}>
                      {row.acf.besonderheit}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href={row.acf.produkt_webseite_url} target="_blank">
                    Produktseite
                  </Button>
                  <Button size="small" color="primary" href={row.acf.preisvergleich_url} target="_blank">
                    Preisvergleich 
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Card className={classes.card} elevation={0}>
                <CardActionArea>
                <CardHeader title='Details' titleTypographyProps={{variant:"body2"}} />
                  <CardContent>
                  <Table size="small" aria-label="purchases" >
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.detailTableCell} align="right">Max. Flächen kapazität</TableCell>
                        <TableCell className={classes.detailTableCell} align="left">{row.acf.gardensize} m²</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell className={classes.detailTableCell} align="right">Max. Steigung</TableCell>
                        <TableCell className={classes.detailTableCell} align="left">{row.acf.inclination} %</TableCell>      
                        </TableRow>
                        <TableRow>
                        <TableCell className={classes.detailTableCell} align="right">Menüführung per App</TableCell>
                        <TableCell className={classes.detailTableCell} align="left"><CheckMarkIcon value={row.acf.menufuhrung_per_app}/></TableCell>  
                        </TableRow>
                        <TableRow>
                        <TableCell className={classes.detailTableCell} align="right">Schnittbreite</TableCell>
                        <TableCell className={classes.detailTableCell} align="left">{row.acf.schnittbreite} cm</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell className={classes.detailTableCell} align="right">Schnitthöhe</TableCell>
                        <TableCell className={classes.detailTableCell} align="left">{row.acf.schnitthohe_min} cm bis {row.acf.schnitthohe_max} cm</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell className={classes.detailTableCell} align="right">Akkulaufzeit</TableCell>
                        <TableCell className={classes.detailTableCell} align="left">{row.acf.akkulaufzeit} min</TableCell>
                        </TableRow>
                        <TableRow>
                        <TableCell className={classes.detailTableCell} align="right">Akkuladezeit</TableCell>
                        <TableCell className={classes.detailTableCell} align="left">{row.acf.akkuladezeit} min</TableCell> 
                        </TableRow>
                        <TableRow>
                        <TableCell className={classes.detailTableCell} align="right">Gewicht</TableCell>
                        <TableCell className={classes.detailTableCell} align="left">{row.acf.gewicht_in_kg} Kg</TableCell>
                        </TableRow>
                        <TableRow>
                      </TableRow>
                    </TableBody>               
                  </Table>
                  </CardContent>
                </CardActionArea>
              
              </Card>
            </Grid>
          </Grid>

            
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );         
}

export default MowerTableRow;
